$primary-color: #27479E;
$secondary-color: #9B9CA0;
$default-white: #FFFFFF;
$hero-image: "https://firebasestorage.googleapis.com/v0/b/stepoffaithchurch-6bf25.appspot.com/o/hero.jpg?alt=media&token=731d6928-0c04-4432-ac87-235f0b30fab1";

/* Leadership */
$head-paster: "https://firebasestorage.googleapis.com/v0/b/stepoffaithchurch-6bf25.appspot.com/o/manny-missy.jpg?alt=media&token=f816c397-4e70-4c26-93de-3bd7f12e7c1a";
$co-paster: "https://firebasestorage.googleapis.com/v0/b/stepoffaithchurch-6bf25.appspot.com/o/richard-helen.jpg?alt=media&token=ebb7efb0-1238-451d-b3e0-fa8d5069a101";


