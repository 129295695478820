@import url(https://fonts.googleapis.com/css?family=Montserrat);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Leadership */
.nav {
  padding: .5rem;
  color: #FFFFFF;
  background: #27479E; }

/* Leadership */
.jumbotron {
  background: url("https://firebasestorage.googleapis.com/v0/b/stepoffaithchurch-6bf25.appspot.com/o/hero.jpg?alt=media&token=731d6928-0c04-4432-ac87-235f0b30fab1") no-repeat top right;
  background-size: cover;
  height: 40vh;
  border-radius: 0rem;
  padding: 0px; }

strong {
  font-size: 1.5rem;
  color: #FFFFFF;
  background: rgba(39, 71, 158, 0.65);
  text-shadow: -1px -1px 0 #27479E, 1px -1px 0 #27479E, -1px 1px 0 #27479E, 1px 1px 0 #27479E;
  padding: 0rem .25rem; }

.welcome {
  height: 40vh; }

/* Leadership */
.heading {
  color: #27479E;
  text-align: center;
  padding: 2rem 0rem; }

.tithely-give-btn {
  background-color: #00DB72;
  font-family: inherit;
  font-weight: bold;
  font-size: 19px;
  padding: 15px 30px;
  border-radius: 4px;
  cursor: pointer;
  background-image: none;
  color: white;
  text-shadow: none;
  display: inline-block;
  float: none;
  border: none;
  position: fixed;
  right: 1rem;
  top: 50%;
  z-index: 999;
  box-shadow: 0px 9px 20px rgba(0, 0, 0, 0.19), 0 2px 7px rgba(0, 0, 0, 0.23); }

@media only screen and (max-width: 600px) {
  .tithely-give-btn {
    right: auto;
    right: initial;
    top: auto;
    top: initial;
    left: 68vw;
    bottom: 2vh; } }

/* Leadership */

/* Leadership */
.service .col-9 {
  text-align: left; }

.service p {
  margin: 0px;
  padding: 1rem; }

.service h3 {
  margin-top: 1.5rem; }

/* Leadership */
.location .col-9 {
  text-align: left; }

.location p {
  margin: 0px;
  padding: 1rem; }

.location h3 {
  margin-top: 1.5rem; }

.location button.btn-info {
  background: #27479E;
  border-color: #27479E; }

/* Leadership */
.contact {
  padding-bottom: 2rem; }

/* Leadership */
body {
  font-family: 'Montserrat', sans-serif;
  color: #333;
  text-align: center; }

.secondary {
  color: #9B9CA0; }

.secondary-background {
  background: rgba(155, 156, 160, 0.15); }

.sub-heading {
  text-align: center; }

/* Leadership */
.footer {
  color: #FFFFFF;
  background: #27479E;
  padding: .5rem; }

.footer p {
  margin-top: 1rem; }

