@import 'src/styles/_colors.scss';

.jumbotron {
    background: url($hero-image) no-repeat top right;
    background-size: cover;
    height: 40vh;
    border-radius: 0rem;
    padding: 0px;
}

strong {
    font-size: 1.5rem;
    color: $default-white;
    background: rgba(39, 71, 158, 0.65);
    text-shadow:
    -1px -1px 0 $primary-color,
    1px -1px 0 $primary-color,
    -1px 1px 0 $primary-color,
    1px 1px 0 $primary-color; 
    padding: 0rem .25rem;
}

.welcome {
    height: 40vh;
}