@import 'src/styles/_colors.scss';

.location .col-9 {
    text-align: left;
}

.location p {
    margin: 0px;
    padding: 1rem;
}

.location {
    h3 {
        margin-top: 1.5rem;
    }
    button.btn-info {
        background: $primary-color;
        border-color: $primary-color;
    }
}