@import 'src/styles/_colors.scss';

.service .col-9 {
    text-align: left;
}

.service p {
    margin: 0px;
    padding: 1rem;
}

.service {
    h3 {
        margin-top: 1.5rem;
    }
}