@import 'src/styles/_colors.scss';

.footer {
    color: $default-white;
    background: $primary-color;
    padding: .5rem;
}

.footer p {
    margin-top: 1rem;
}