.tithely-give-btn {
    background-color: #00DB72;
    font-family: inherit;
    font-weight: bold;
    font-size: 19px; 
    padding: 15px 30px; 
    border-radius: 4px; 
    cursor: pointer; 
    background-image: none; 
    color: white; 
    text-shadow: none; 
    display: inline-block; 
    float: none; 
    border: none;
    position: fixed;
    right: 1rem;
    top: 50%;
    z-index: 999;
    box-shadow: 0px 9px 20px rgba(0, 0, 0, 0.19), 0 2px 7px rgba(0, 0, 0, 0.23);
}

@media only screen and (max-width: 600px) {
    .tithely-give-btn {
        right: initial;
        top: initial;
        left: 68vw;
        bottom: 2vh;
    }
  }