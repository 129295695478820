@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import 'styles/_colors.scss';

body {
  font-family: 'Montserrat', sans-serif;
  color: #333;
  text-align: center;
}

.secondary {
  color: $secondary-color;
}

.secondary-background {
background: rgba(155, 156, 160,.15);
}

.sub-heading {
  text-align: center;
}